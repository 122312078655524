<template>
    <v-row v-if="isClassification && modelFeature && improvementPoints" style="max-height: 500px; overflow-y: auto;">
        <v-card class="elevation-0 ma-4" color="#ececfb">
            <v-card-title><v-icon left color="#384048">mdi-star</v-icon>特徴</v-card-title>
            <v-card-text>
                {{ modelFeature }}
            </v-card-text>
        </v-card>
        <v-card class="elevation-0 ma-4" color="#e3effa">
            <v-card-title><v-icon left color="#384048">mdi-lightbulb-on</v-icon>改善点</v-card-title>
            <v-card-text>
                {{ improvementPoints }}
            </v-card-text>
        </v-card>
    </v-row>
</template>

<script>
export default {
    props: {
        isClassification: {
            type: Boolean,
            required: true,
        },
        modelFeature: {
            type: String,
            required: true,
        },
        improvementPoints: {
            type: String,
            required: true,
        },
    },
};
</script>
